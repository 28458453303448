/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
    /* height: 40px; */
    height: 1.1876em;
    padding: 18.5px 14px;
    /* width: 100%; */
    /* width: 450px - (padding 14px + 14px) - (border 1px + 1px);  */
    max-width: 420px; 
    margin-top: 16px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.87);
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    box-sizing: content-box;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }